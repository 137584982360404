<template>
  <div class="text-center">
    <br>
    <br>
    <h1 class="text-center product_title">
        Thanks for placing your order
    </h1>
    <br>
    <br>
    <p>
        We have received your order and will begin processing right away. An
        email confirmation was sent to you. It contains details of the items you purchased.
    </p>
</div>

</template>

<script>
export default {
    name: 'Success',

}
</script>

<style>

</style>